import React, { useState, useEffect } from 'react';
import { Package } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  
  if (diffHours < 24) return `${diffHours} hours ago`;
  if (diffDays === 1) return 'Yesterday';
  if (diffDays < 7) return `${diffDays} days ago`;
  return date.toLocaleDateString();
};

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'completed':
    case 'delivered':
      return 'bg-green-100 text-green-800';
    case 'in_transit':
    case 'in transit':
      return 'bg-blue-100 text-blue-800';
    case 'processing':
      return 'bg-yellow-100 text-yellow-800';
    case 'cancelled':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const OrderImages = ({ image_ids, items_count }) => {
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imagePromises = image_ids.slice(0, 2).map(async (imageData) => {
          const response = await fetch(`https://api.canvashelper.com/api/etsy/listings/${imageData.listing_id}/images/${imageData.image_id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          const data = await response.json();
          return data.url_570xN;
        });
        
        const imageUrls = await Promise.all(imagePromises);
        setImages(imageUrls);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
    
    if (image_ids?.length) {
      fetchImages();
    }
  }, [image_ids]);

  return (
    <div className="relative w-10 h-10">
      {images.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Product ${index + 1}`}
          className="absolute rounded-full w-10 h-10 object-cover border-2 border-white"
          style={{
            top: `${index * -8}px`,
            left: `${index * 8}px`,
            zIndex: images.length - index
          }}
        />
      ))}
      {items_count > 1 && (
        <span className="absolute -bottom-1 -right-1 bg-gray-900 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center z-10">
          {items_count}
        </span>
      )}
    </div>
  );
};

const OrderCard = ({ order, showStoreName }) => {
  const navigate = useNavigate();
  
  return (
    <div 
      className="flex items-center gap-4 p-2 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
      onClick={() => navigate(`/orders/${order.id}`)}
    >
      {order.image_ids?.length ? (
        <OrderImages image_ids={order.image_ids} items_count={order.items_count} />
      ) : (
        <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0">
          <Package className="w-5 h-5 text-blue-600" />
        </div>
      )}
      <div className="flex-grow">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-gray-900">
            #{order.order_number}
          </p>
          <p className="text-sm text-gray-900">
            <span className="font-medium">{order.items_count}</span>
            <span className="text-gray-500 mx-1">items</span>
            <span className="font-medium">${order.total_amount}</span>
          </p>
        </div>
        {showStoreName && order.shop_name && (
          <p className="text-xs text-gray-500 mt-1">{order.shop_name}</p>
        )}
        <div className="flex justify-between items-center mt-1">
          <span className={`text-xs font-medium px-2 py-0.5 rounded-full ${getStatusColor(order.status)}`}>
            {order.status}
          </span>
          <span className="text-xs text-gray-500">
            {formatDate(order.created_at)}
          </span>
        </div>
      </div>
    </div>
  );
};

const OpenOrders = ({ period, show_all_stores, store_id }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Query parametrelerini oluştur
        const queryParams = new URLSearchParams({
          show_all_stores: show_all_stores || false
        });
        
        // Period varsa ekle
        if (period) {
          queryParams.append('period', period);
        }
        
        // Eğer tüm mağazalar seçili değilse ve store_id varsa ekle
        if (!show_all_stores && store_id) {
          queryParams.append('store_id', store_id);
        }

        const response = await fetch(`https://api.canvashelper.com/api/dashboard/open-orders?${queryParams}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          
        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }
        const data = await response.json();
        setOrders(data.orders);
        setError(null);
      } catch (err) {
        setError(err.message);
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [period, show_all_stores, store_id]); // Dependencies'e yeni parametreleri ekledim

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold text-gray-900">
          Open Orders {!show_all_stores && store_id ? 'for Selected Store' : ''}
        </h2>
        {orders.length > 0 && (
          <span className="text-sm text-gray-500">{orders.length} order{orders.length !== 1 ? 's' : ''}</span>
        )}
      </div>

      <div className="space-y-4 max-h-[280px] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        {loading ? (
          <div className="space-y-4">
            {[1, 2, 3].map(i => (
              <div key={i} className="flex items-center gap-4 p-2 bg-gray-50 rounded-lg animate-pulse">
                <div className="w-10 h-10 bg-gray-200 rounded-full" />
                <div className="flex-grow">
                  <div className="h-4 bg-gray-200 rounded w-3/4 mb-2" />
                  <div className="h-3 bg-gray-200 rounded w-1/2" />
                </div>
              </div>
            ))}
          </div>
        ) : error ? (
          <div className="text-center py-4">
            <p className="text-red-500">{error}</p>
            <button 
              onClick={() => window.location.reload()} 
              className="text-sm text-blue-600 hover:text-blue-700 mt-2"
            >
              Try again
            </button>
          </div>
        ) : orders.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No open orders {!show_all_stores && store_id ? 'for the selected store' : ''}
          </div>
        ) : (
          orders.map((order) => (
            <OrderCard 
              key={order.id} 
              order={order} 
              showStoreName={show_all_stores}
            />
          ))
        )}
      </div>

      {orders.length > 0 && (
        <button 
          onClick={() => navigate('/orders')} 
          className="w-full mt-4 text-sm text-blue-600 hover:text-blue-700 font-medium"
        >
          View All Orders
        </button>
      )}
    </div>
  );
};

export default OpenOrders;