import React, { useState, useEffect } from 'react';
import { DollarSign, ShoppingCart, TrendingUp, BarChart2, ArrowUp, ArrowDown } from 'lucide-react';

const Stats = ({ period, show_all_stores, store_id }) => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setLoading(true);
        // Query parametrelerini oluştur
        const queryParams = new URLSearchParams({
          period: period,
          show_all_stores: show_all_stores || false
        });
        
        // Eğer tüm mağazalar seçili değilse ve store_id varsa ekle
        if (!show_all_stores && store_id) {
          queryParams.append('store_id', store_id);
        }

        const response = await fetch(`https://api.canvashelper.com/api/metrics/summary?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch metrics');
        }
        
        const data = await response.json();
        setMetrics(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [period, show_all_stores, store_id]); // Dependencies'e yeni parametreleri ekledim

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="bg-white rounded-xl border border-gray-200 p-6 animate-pulse">
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
            <div className="h-8 bg-gray-200 rounded w-3/4"></div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-xl text-red-600">
        Failed to load metrics: {error}
      </div>
    );
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  const renderTrend = (value) => {
    if (value > 0) {
      return (
        <div className="flex items-center mt-4 text-sm">
          <ArrowUp className="w-4 h-4 text-green-500 mr-1" />
          <span className="text-green-500 font-medium">{value}%</span>
          <span className="text-gray-500 ml-1">vs last period</span>
        </div>
      );
    }
    return (
      <div className="flex items-center mt-4 text-sm">
        <ArrowDown className="w-4 h-4 text-red-500 mr-1" />
        <span className="text-red-500 font-medium">{Math.abs(value)}%</span>
        <span className="text-gray-500 ml-1">vs last period</span>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Total Revenue</p>
            <p className="text-2xl font-bold text-gray-900 mt-1">
              {formatCurrency(metrics?.totalRevenue || 0)}
            </p>
          </div>
          <div className="w-12 h-12 bg-blue-50 flex items-center justify-center rounded-full">
            <DollarSign className="w-6 h-6 text-blue-600" />
          </div>
        </div>
        {metrics && renderTrend(metrics.revenueGrowth)}
      </div>

      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Total Orders</p>
            <p className="text-2xl font-bold text-gray-900 mt-1">
              {metrics?.totalOrders || 0}
            </p>
          </div>
          <div className="w-12 h-12 bg-purple-50 flex items-center justify-center rounded-full">
            <ShoppingCart className="w-6 h-6 text-purple-600" />
          </div>
        </div>
        {metrics && renderTrend(metrics.orderGrowth)}
      </div>

      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Net Profit</p>
            <p className="text-2xl font-bold text-gray-900 mt-1">
              {formatCurrency(metrics?.netProfit || 0)}
            </p>
          </div>
          <div className="w-12 h-12 bg-green-50 flex items-center justify-center rounded-full">
            <TrendingUp className="w-6 h-6 text-green-600" />
          </div>
        </div>
        {metrics && renderTrend(metrics.profitGrowth)}
      </div>

      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Avg Order Value</p>
            <p className="text-2xl font-bold text-gray-900 mt-1">
              {formatCurrency(metrics?.avgOrderValue || 0)}
            </p>
          </div>
          <div className="w-12 h-12 bg-orange-50 flex items-center justify-center rounded-full">
            <BarChart2 className="w-6 h-6 text-orange-600" />
          </div>
        </div>
        {metrics && renderTrend(metrics.avgOrderGrowth)}
      </div>
    </div>
  );
};

export default Stats;