import RecentReviews from '../components/ui/RecentReviews';
import TopProducts from '../components/ui/TopProducts';
import OpenOrders from '../components/ui/OpenOrders';
import OrderStatus from '../components/ui/OrderStatus';
import Stats from '../components/ui/Stats';
import SalesChart from '../components/ui/SalesChart';
import React, { useState, useEffect } from 'react';
import { 
  TrendingUp, DollarSign, ShoppingCart, Package, 
  Users, Star, Clock, BarChart2, ArrowUp, ArrowDown 
} from 'lucide-react';

const Dashboard = () => {
  const [activeOrderTab, setActiveOrderTab] = useState('pending-feedback');
  const [selectedPeriod, setSelectedPeriod] = useState('last30days');
  const [showAllStores, setShowAllStores] = useState(true);
  const [lastSelectedStore, setLastSelectedStore] = useState(
    localStorage.getItem('last_selected_store') || ''
  );

  // Periyoda göre gün sayısını hesaplayan yardımcı fonksiyon
  const getPeriodText = (periodType) => {
    const periodMap = {
      'last7days': '7 Days',
      'last30days': '30 Days',
      'today': '7 Days', // today seçildiğinde last7days olarak işleniyor
      'thisMonth': 'This Month',
      'lastMonth': 'Month',
      'thisYear': 'This Year',
      'allTime': 'All Time'
    };
    return periodMap[periodType] || '30 Days';
  };

  // Local storage'daki değişiklikleri dinle
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'last_selected_store') {
        setLastSelectedStore(e.newValue || '');
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Filter parametrelerini oluştur
  const filterParams = {
    period: selectedPeriod,
    show_all_stores: showAllStores,
    store_id: !showAllStores ? lastSelectedStore : undefined
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
            <p className="text-gray-500 mt-1">Sales performance and analytics</p>
          </div>
          <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
            {/* Period Filter */}
            <select 
              className="px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600"
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
            >
              <option value="today">Today</option>
              <option value="last7days">Last 7 Days</option>
              <option value="last30days">Last 30 Days</option>
              <option value="thisMonth">This Month</option>
              <option value="lastMonth">Last Month</option>
              <option value="thisYear">This Year</option>
              <option value="allTime">All Time</option>
            </select>

            {/* Store Filter */}
            <label className="flex items-center gap-2 text-sm text-gray-600">
              <input
                type="checkbox"
                className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={showAllStores}
                onChange={(e) => setShowAllStores(e.target.checked)}
              />
              All Stores
            </label>
          </div>
        </div>
        {/* Stats */}
        <Stats {...filterParams} />

        {/* Rest of the components... */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 bg-white rounded-xl border border-gray-200 p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Sales Overview</h2>
              <span className="text-sm text-gray-500">Last {getPeriodText(selectedPeriod)}</span>
            </div>
            <div className="h-80 bg-gray-50 rounded-lg flex items-center justify-center text-gray-400">
              <SalesChart {...filterParams} />
            </div>
          </div>
          <OpenOrders {...filterParams} />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
          <TopProducts {...filterParams} />
          <OrderStatus {...filterParams} />
          <RecentReviews {...filterParams} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;