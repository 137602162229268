import React from 'react';
import { FileText, MessageCircle, Truck } from 'lucide-react';

const QuickActions = () => {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Quick Actions</h2>
        <div className="space-y-3">
          <button className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2">
            <FileText className="w-4 h-4" />
            Generate Invoice
          </button>
          <button className="w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center gap-2">
            <MessageCircle className="w-4 h-4" />
            Message Buyer
          </button>
          <button className="w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center gap-2">
            <Truck className="w-4 h-4" />
            Feedback Request
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickActions;