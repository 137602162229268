import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import ProductEditModal from './ProductEditModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Plus, Filter, SortAsc, SortDesc, Wand2, Grid, Trash2, Edit2, CheckSquare } from 'lucide-react';
import { quantum } from 'ldrs'; // uiball yükleme animasyonu
quantum.register();

function DraftProducts() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filter, setFilter] = useState('all');
  const [generatedData, setGeneratedData] = useState([]);
  const [variationProfiles, setVariationProfiles] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState('desc');
  const [showModal, setShowModal] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);
  const [loading, setLoading] = useState(false); // Loader için state
  const [sections, setSections] = useState([]);
  const [productTips, setProductTips] = useState({});


  const apiKey = process.env.REACT_APP_OPENAI;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true); // Ürünleri yüklerken loader'ı göster
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get('https://etsyhelper.onrender.com/products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false); // Yükleme tamamlandığında loader'ı gizle
      }
    };

    const fetchVariationProfiles = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get('https://api.canvashelper.com/api/variation_profiles', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setVariationProfiles(response.data);
      } catch (error) {
        console.error('Error fetching variation profiles:', error);
      }
    };

    fetchVariationProfiles();
    fetchProducts();
  }, [user]);

  const getVariationProfileName = (variationProfileId) => {
    const profile = variationProfiles.find((p) => p.id === variationProfileId);
    return profile ? profile.name : 'No Profile';
  };


  useEffect(() => {
    const fetchSections = async () => {
        try {
            const token = localStorage.getItem('token');
            const storeId = localStorage.getItem('last_selected_store');

            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/sections-from-db`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log("Sections from backend:", response.data.sections);
            setSections(response.data.sections);
        } catch (error) {
            console.error('Sections bilgisi alınamadı:', error);
            toast.error('Failed to fetch sections.');
        }
    };

    fetchSections();
}, []);


// Bu fonksiyon, section_id'ye göre section başlığını döndürecek.
const getSectionTitle = (sectionId) => {
    const section = sections.find(s => s.id === sectionId);
    return section ? section.title : 'No Section';
};
  

  const assignSections = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('No token found');
        }

        // Get the selected store ID from localStorage
        const storeId = localStorage.getItem('last_selected_store'); 
        if (!storeId) {
            throw new Error('No store selected');
        }

        // Ensure products are selected before assigning sections
        if (selectedProducts.length === 0) {
            toast.error('No products selected to assign sections.');
            return;
        }

        // Send store ID and selected product IDs to the backend
        const response = await axios.post('https://etsyhelper.onrender.com/assign-sections', {
            store_id: storeId,
            product_ids: selectedProducts,  // Send selected products
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        // Backend'den gelen güncellenmiş ürün bilgilerini kullanarak products state'ini güncelliyoruz
        if (response.data.updated_products_info) {
          setProducts(prevProducts => prevProducts.map(product => {
              const updatedInfo = response.data.updated_products_info.find(
                  info => info.product_id === product.id
              );
              
              if (updatedInfo) {
                  return {
                      ...product,
                      section_id: updatedInfo.section_id,
                      section_title: getSectionTitle(updatedInfo.section_id)
                  };
              }
              return product;
          }));
      }

        toast.success('Sections assigned successfully for the selected store and products');
    } catch (error) {
        console.error('Error assigning sections:', error);
        toast.error('Failed to assign sections');
    }
};


  const toggleSelectProduct = (productId) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const selectAllProducts = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products.map(product => product.id));
    }
  };

  const handleTipsChange = (productId, value) => {
    setProductTips(prev => ({
      ...prev,
      [productId]: value
    }));
  };

  const generateProductData = async () => {
    try {
        setLoading(true); // Ürün verisi oluşturulurken loader'ı göster
        const token = localStorage.getItem('token');

        // Önce ürün isimlerini oluştur
        const selectedProductData = selectedProducts.map(productId => {
            const product = products.find(p => p.id === productId);

            // `base_image`in boş olup olmadığını kontrol edin
            if (!product.base_image) {
                throw new Error(`Base image URL is missing for product ID: ${productId}`);
            }

            return {
                id: productId,
                image_url: product.base_image, // `base_image` burada `image_url` olarak kullanılacak
                tips: productTips[productId] || '' // Tips bilgisini ekle
            };
        });

        // İlk adım: Ürün isimlerini oluşturun
        const nameResponse = await axios.post('https://etsyhelper.onrender.com/generate', {
            apiKey,
            products: selectedProductData
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        if (nameResponse.data.error) {
            throw new Error(nameResponse.data.error);
        }

        // İkinci adım: Description oluşturma, burada `nameResponse`dan gelen `title` kullanılıyor
        const descriptionResponse = await axios.post('https://etsyhelper.onrender.com/generate-product-description', {
            products: nameResponse.data.map(product => ({
                product_id: product.product_id,
                name: product.title // `title` değerini kullanıyoruz
            }))
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Üçüncü adım: Tags oluşturma, burada `nameResponse`dan gelen `title` kullanılıyor
        const tagsResponse = await axios.post('https://etsyhelper.onrender.com/generate-product-tags', {
            products: nameResponse.data.map(product => ({
                product_id: product.product_id,
                name: product.title // `title` değerini kullanıyoruz
            }))
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Ürünleri güncelleyin
        const updatedProducts = products.map(product => {
            const updatedProduct = nameResponse.data.find(p => p.product_id === product.id);
            const descriptionProduct = descriptionResponse.data.find(p => p.product_id === product.id);
            const tagsProduct = tagsResponse.data.find(p => p.product_id === product.id);

            return updatedProduct ? {
                ...product,
                name: updatedProduct.title, // İsmi güncelleyin
                description: descriptionProduct ? descriptionProduct.description : product.description, // Açıklamayı güncelleyin
                tags: tagsProduct ? tagsProduct.tags : product.tags // Etiketleri güncelleyin
            } : product;
        });


        setProductTips({});
        setSelectedProducts([]);
        setProducts(updatedProducts); // products state'ini güncelleyin
        setSelectedProducts([]); // Seçili ürünleri temizle
        toast.success('Product name, description, and tags generated successfully!');
    } catch (error) {
        console.error('Error generating product data:', error);
        setError(error.message);
        toast.error('Failed to generate product data.');
    } finally {
        setLoading(false); // Yükleme tamamlandığında loader'ı gizle
    }
};



  const deleteProduct = async (productId) => {
    try {
      setLoading(true); // Silme işlemi başlamadan önce loader'ı göster
      const token = localStorage.getItem('token');
      await axios.delete(`https://etsyhelper.onrender.com/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(products.filter(product => product.id !== productId));
      toast.success('Product deleted successfully!');
    } catch (error) {
      console.error('Error deleting product:', error);
      setError('Failed to delete product');
      toast.error('Failed to delete product.');
    } finally {
      setLoading(false); // Silme işlemi tamamlandığında loader'ı gizle
    }
  };

  const openEditModal = (productId) => {
    setEditingProductId(productId);
    setShowModal(true);
  };

  const handleProductUpdate = () => {
    setShowModal(false);
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get('https://etsyhelper.onrender.com/products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data);
        toast.success('Product updated successfully!');
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error('Failed to update product.');
      }
    };
    fetchProducts();
  };
  

  const filteredProducts = products.filter(product => {
    if (filter === 'preparing') return !product.name || !product.description || !product.tags || !product.images;
    if (filter === 'ready') return product.name && product.description && product.tags && product.images;
    return true;
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    if (sortOrder === 'desc') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(sortedProducts.length / productsPerPage);

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Draft Products</h1>
          <p className="mt-1 text-sm text-gray-600">Manage and prepare your products for listing</p>
        </div>
        
        {/* Action Bar */}
        <div className="bg-white p-3 rounded-lg shadow-sm mb-4">
          <div className="flex flex-wrap gap-2 items-center justify-between">
            {/* Left Side Actions */}
            <div className="flex gap-2">
              <button 
                onClick={selectAllProducts}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
              >
                <CheckSquare size={16} />
                {selectedProducts.length === products.length ? 'Deselect All' : 'Select All'}
              </button>
              
              <button 
                onClick={generateProductData}
                className="magic flex items-center gap-1.5 px-3 py-1.5 text-sm bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
              >
                <Wand2 size={16} />
                Generate Content
              </button>

              <button
                onClick={assignSections}
                className="autosection flex items-center gap-1.5 px-3 py-1.5 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                <Grid size={16} />
                Assign Sections
              </button>
            </div>

            {/* Right Side Filters */}
            <div className="flex gap-2">
              <div className="filter flex rounded-md overflow-hidden border border-gray-300">
                <button 
                  onClick={() => setFilter('all')}
                  className={`px-3 py-1.5 text-sm ${filter === 'all' ? 'bg-gray-100 text-gray-800' : 'bg-white text-gray-600'}`}
                >
                  All
                </button>
                <button 
                  onClick={() => setFilter('preparing')}
                  className={`px-3 py-1.5 text-sm ${filter === 'preparing' ? 'bg-yellow-50 text-yellow-700' : 'bg-white text-gray-600'}`}
                >
                  Preparing
                </button>
                <button 
                  onClick={() => setFilter('ready')}
                  className={`px-3 py-1.5 text-sm ${filter === 'ready' ? 'bg-green-50 text-green-700' : 'bg-white text-gray-600'}`}
                >
                  Ready
                </button>
              </div>

              <button
                onClick={() => setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                {sortOrder === 'desc' ? <SortDesc size={16} /> : <SortAsc size={16} />}
                {sortOrder === 'desc' ? 'Latest' : 'Oldest'}
              </button>
            </div>
          </div>
        </div>

        {/* Loading Overlay */}
        {loading && (
          <div className="fixed inset-0 bg-black/30 backdrop-blur-sm z-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-xl shadow-lg flex flex-col items-center justify-center gap-3">
              <l-quantum size="45" speed="1.75" color="black"></l-quantum>
              <p className="text-sm text-gray-600">Processing...</p>
            </div>
          </div>
        )}

        {/* Products Grid içindeki ürün kartını güncelleyelim */}
        <div className="grid gap-4 w-full">
          {currentProducts.map((product, index) => (
            <div
              key={index}
              className={`bg-white rounded-lg shadow-sm transition-all hover:shadow-md w-full ${
                product.name && product.description && product.tags ? 'border-l-4 border-green-500' : 'border-l-4 border-yellow-500'
              }`}
            >
              <div className="p-4">
                {/* İlk satır: Checkbox, Durum ve Aksiyonlar */}
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={selectedProducts.includes(product.id)}
                      onChange={() => toggleSelectProduct(product.id)}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className={`px-2 py-0.5 rounded-full text-xs font-medium
                      ${product.name && product.description && product.tags && product.images
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {product.name && product.description && product.tags && product.images
                        ? 'Ready'
                        : 'Preparing'}
                    </span>
                  </div>
                  
                  <div className="editDelete flex gap-1">
                    <button
                      onClick={() => openEditModal(product.id)}
                      className="p-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-md transition-colors"
                    >
                      <Edit2 size={16} />
                    </button>
                    <button
                      onClick={() => deleteProduct(product.id)}
                      className="p-1.5 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-md transition-colors"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </div>

                {/* İkinci satır: Resim ve İçerik yan yana */}
                <div className="flex gap-4">
                  {/* Resim */}
                  <div className="flex-shrink-0">
                    <img
                      src={product.base_image}
                      alt={`product-${index}`}
                      className="w-24 h-24 object-contain rounded-lg border border-gray-200"
                    />
                    {/* Tips input alanı */}
                    <div className="mt-2">
                      <input
                        type="text"
                        value={productTips[product.id] || ''}
                        onChange={(e) => handleTipsChange(product.id, e.target.value)}
                        placeholder="Tips for image."
                        className="w-full text-xs p-1.5 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  {/* İçerik */}
                  <div className="flex-grow min-w-0">
                    <h3 className="text-sm font-medium text-gray-900 truncate">
                      {product.name || 'Untitled Product'}
                    </h3>
                    <p className="mt-1 text-xs text-gray-600 line-clamp-2">
                      {product.description || 'No description available'}
                    </p>

                    {/* Tags */}
                    <div className="mt-2">
                      <div className="flex flex-wrap gap-1.5">
                        {product.tags
                          ? product.tags.split(',').map((tag, idx) => (
                              <span
                                key={idx}
                                className="px-1.5 py-0.5 bg-gray-100 text-gray-600 text-xs rounded whitespace-nowrap"
                              >
                                {tag.trim()}
                              </span>
                            ))
                          : <span className="text-gray-400 text-xs">No tags</span>
                        }
                      </div>
                    </div>

                    {/* Section ve Variation Profile bilgileri */}
                    <div className="mt-2 flex items-center gap-3 text-xs">
                      <span className="text-gray-600">
                        Section: <span className="font-medium">{getSectionTitle(product.section_id)}</span>
                      </span>
                      <span className="text-gray-600">
                        Profile: <span className="font-medium">{getVariationProfileName(product.variation_profile_id)}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="mt-6 flex justify-center gap-2">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md ${
              currentPage === 1
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-300'
            }`}
          >
            Previous
          </button>
          
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-4 py-2 rounded-md ${
                currentPage === index + 1
                  ? 'bg-blue-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-300'
              }`}
            >
              {index + 1}
            </button>
          ))}
          
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-md ${
              currentPage === totalPages
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-300'
            }`}
          >
            Next
          </button>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-lg">
          <p className="text-red-600">{error}</p>
        </div>
      )}

      {/* Modals */}
      <ProductEditModal
        productId={editingProductId}
        showModal={showModal}
        setShowModal={setShowModal}
        onProductUpdate={handleProductUpdate}
      />
      <ToastContainer />
    </div>
  );
};

export default DraftProducts;