import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { format, parseISO } from 'date-fns';

const SalesChart = ({ period = 'last30days', show_all_stores, store_id }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        setLoading(true);
        // Query parametrelerini oluştur
        const queryParams = new URLSearchParams({
          period: period,
          show_all_stores: show_all_stores || false
        });
        
        // Eğer tüm mağazalar seçili değilse ve store_id varsa ekle
        if (!show_all_stores && store_id) {
          queryParams.append('store_id', store_id);
        }

        const response = await fetch(`https://api.canvashelper.com/api/metrics/sales-chart?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch chart data');
        }
        
        const data = await response.json();
        setChartData(data.map(item => ({
          ...item,
          name: format(parseISO(item.date), 'MMM d'),
          sales: item.total_revenue,
          orders: item.total_orders
        })));
      } catch (err) {
        setError(err.message);
        setChartData([]); // Hata durumunda veriyi temizle
      } finally {
        setLoading(false);
      }
    };

    fetchChartData();
  }, [period, show_all_stores, store_id]); // Dependencies'e yeni parametreleri ekledim

  if (loading) {
    return (
      <div className="h-80 bg-gray-50 flex items-center justify-center">
        <div className="animate-pulse w-full h-64 bg-gray-100 rounded-lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-80 bg-red-50 flex items-center justify-center text-red-600 rounded-lg">
        Failed to load chart data
      </div>
    );
  }

  if (chartData.length === 0) {
    return (
      <div className="h-80 bg-gray-50 flex items-center justify-center text-gray-500 rounded-lg">
        No data available for this period {!show_all_stores && store_id ? 'in the selected store' : ''}
      </div>
    );
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
          <p className="text-sm font-medium text-gray-900 mb-2">{label}</p>
          <div className="space-y-1">
            <p className="text-sm">
              <span className="text-blue-600 font-medium">{formatCurrency(payload[0].value)}</span>
              <span className="text-gray-500"> sales</span>
            </p>
            <p className="text-sm">
              <span className="text-green-600 font-medium">{payload[1].value}</span>
              <span className="text-gray-500"> orders</span>
            </p>
            {payload[0].payload.shop_name && (
              <p className="text-xs text-gray-500">
                {payload[0].payload.shop_name}
              </p>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={320}>
      <LineChart 
        data={chartData} 
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
        <XAxis 
          dataKey="name" 
          stroke="#6b7280"
          tick={{ fill: '#6b7280' }}
        />
        <YAxis 
          yAxisId="left"
          stroke="#6b7280"
          tick={{ fill: '#6b7280' }}
          tickFormatter={value => formatCurrency(value)}
        />
        <YAxis 
          yAxisId="right" 
          orientation="right"
          stroke="#6b7280"
          tick={{ fill: '#6b7280' }}
        />
        <Tooltip content={customTooltip} />
        <Legend />
        <Line 
          yAxisId="left"
          type="monotone" 
          dataKey="sales" 
          name="Sales ($)"
          stroke="#3b82f6" 
          strokeWidth={2}
          dot={{ r: 4, fill: '#3b82f6' }}
          activeDot={{ r: 6 }}
        />
        <Line 
          yAxisId="right"
          type="monotone" 
          dataKey="orders" 
          name="Orders"
          stroke="#10b981" 
          strokeWidth={2}
          dot={{ r: 4, fill: '#10b981' }}
          activeDot={{ r: 6 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SalesChart;