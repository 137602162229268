import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import CustomerInformation from './CustomerInformation';
import OrderHeader from './OrderHeader';
import OrderProducts from './OrderProducts';
import ShippingInformation from './ShippingInformation';
import QuickActions from './QuickActions';
import OrderTimeline from './OrderTimeline';
import ProfitAnalysis from './ProfitAnalysis';
import OrderNotes from './OrderNotes';

const OrderDetailPage = () => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await fetch(`https://etsyhelper.onrender.com/api/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (!response.ok) throw new Error('Error fetching order details');

        const data = await response.json();
        setOrderData(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (orderId) fetchOrderDetails();
  }, [orderId]);

  if (loading) return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
    </div>
  );

  if (error) return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-red-50 text-red-600 p-4 rounded-lg flex items-center gap-2">
        <AlertCircle className="w-5 h-5" />
        <p>{error}</p>
      </div>
    </div>
  );

  if (!orderData) return null;

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header'ı ekliyoruz */}
        <OrderHeader orderData={orderData} />
        
        {/* Grid Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Sol Kolon */}
          <div className="lg:col-span-2 space-y-6">
            <CustomerInformation orderData={orderData} />
            <OrderProducts orderData={orderData} />
            <ShippingInformation orderData={orderData} />
          </div>
          
          {/* Sağ Kolon */}
          <div className="space-y-6">
            <QuickActions />
            <OrderTimeline orderData={orderData} />
            <ProfitAnalysis orderData={orderData} />
            <OrderNotes />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailPage;