import React from 'react';
import { ExternalLink, Gift } from 'lucide-react';
import { formatPrice, formatDate } from './helperFunctions';

const OrderHeader = ({ orderData }) => {
  const getStatusBadge = (status) => {
    const statusColors = {
      'sold': 'bg-green-100 text-green-800',
      'pending': 'bg-yellow-100 text-yellow-800',
      'cancelled': 'bg-red-100 text-red-800'
    };
    
    return (
      <span className={`px-3 py-1 rounded-full text-sm font-medium ${statusColors[status] || 'bg-gray-100 text-gray-800'}`}>
        {status === 'sold' ? 'Completed' : status}
      </span>
    );
  };

  const showGiftInfo = orderData.is_gift;

  return (
    <div className="mb-8">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 sm:gap-0">
        {/* Sol Kısım - Sipariş Bilgileri */}
        <div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900">
              Order #{orderData.receipt_id}
            </h1>
            <div className="flex items-center gap-2">
              <span className="text-xl sm:text-2xl font-bold text-green-600">
                {formatPrice(orderData.total_price)}
              </span>
              {showGiftInfo && (
                <div className="relative group">
                  <Gift className="w-5 h-5 text-pink-500 cursor-help" />
                  <div className="absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-900 rounded whitespace-nowrap">
                    This is a gift order
                  </div>
                </div>
              )}
            </div>
          </div>
          <p className="text-sm sm:text-base text-gray-500 mt-1">
            Placed on {formatDate(orderData.dates.created)}
          </p>
        </div>

        {/* Sağ Kısım - Durum ve Buton */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3">
          <div className="w-full sm:w-auto">
            {getStatusBadge(orderData.status)}
          </div>
          <button 
            onClick={() => window.open(`https://www.etsy.com/your/orders/${orderData.receipt_id}`, '_blank')}
            className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            <ExternalLink className="w-4 h-4 mr-2" />
            View on Etsy
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderHeader;