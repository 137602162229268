import React, { useState, useEffect } from 'react';
import { Search, Filter, ChevronDown, ChevronUp, MoreHorizontal, Calendar, User, Package, PackageX, Eye } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const [filter, setFilter] = useState('all');
  const [showDropdown, setShowDropdown] = useState(null);
  const ordersPerPage = 50;

  // API'den verileri çekme
  const fetchOrders = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token bulunamadı');
      }

      // API parametrelerini hazırla
      const params = new URLSearchParams({
        page: currentPage,
        limit: ordersPerPage,
        sort_by: sortConfig.key,
        sort_direction: sortConfig.direction,
        status: filter !== 'all' ? filter : '',
        search: searchTerm,
      });

      const response = await axios.get(`https://etsyhelper.onrender.com/api/orders?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data } = response;
      setOrders(data.orders);
      setTotalPages(data.total_pages);
      setTotalItems(data.total_items);
      
    } catch (err) {
      console.error('Siparişler yüklenirken hata:', err);
      setError(err.response?.data?.message || 'Siparişler yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  // Filtreleme, arama veya sayfa değiştiğinde verileri yeniden çek
  useEffect(() => {
    fetchOrders();
  }, [currentPage, sortConfig, filter, searchTerm]);

  // Dışarı tıklandığında dropdown'ı kapat
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && !event.target.closest('.dropdown-container')) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showDropdown]);

  // Arama debounce
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        fetchOrders();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const getStatusColor = (status) => {
    const colors = {
      canceled: 'bg-red-100 text-yellow-800',
      sold: 'bg-yellow-100 text-yellow-800',
      placed: 'bg-orange-100 text-blue-800',
      shipped: 'bg-purple-100 text-purple-800',
      delivered: 'bg-blue-100 text-green-800',
      completed: 'bg-green-100 text-green-800',
    };
    return colors[status] || 'bg-gray-100 text-gray-800';
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatPrice = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const ViewOrderButton = ({ orderId }) => {
    const navigate = useNavigate();
  
    const handleOrderDetail = (e, id) => {
      e.stopPropagation(); // Eğer parent elementte bir onClick varsa onu engellemek için
      navigate(`/orders/${id}`);
    };
  
    return (
      <button
        onClick={(e) => handleOrderDetail(e, orderId)}
        className="p-2 hover:bg-gray-100 rounded-full focus:outline-none transition-colors duration-200"
        title="Detayları Görüntüle"
      >
        <Eye className="text-gray-500 hover:text-gray-700" size={18} />
      </button>
    );
  };

  // Sipariş işlemleri
  const handleOrderDetail = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://etsyhelper.onrender.com/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Detay modalını aç veya detay sayfasına yönlendir
      console.log('Sipariş detayı:', response.data);
    } catch (error) {
      console.error('Sipariş detayı alınırken hata:', error);
    }
  };

  const handleStatusUpdate = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
      // Status güncelleme modalı açılabilir
      console.log('Durum güncelleme:', orderId);
    } catch (error) {
      console.error('Durum güncellenirken hata:', error);
    }
  };

  const handleOrderCancel = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
      // Onay modalı gösterilebilir
      console.log('Sipariş iptal:', orderId);
    } catch (error) {
      console.error('Sipariş iptal edilirken hata:', error);
    }
  };
  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <PackageX size={48} className="text-gray-400 mb-4" />
      <h3 className="text-lg font-medium text-gray-900 mb-2">Henüz sipariş bulunmuyor</h3>
      <p className="text-gray-500 text-center max-w-sm mb-4">
        Şu anda görüntülenecek sipariş bulunmuyor. Yeni siparişler geldiğinde burada listelenecektir.
      </p>
      <button
        onClick={() => {
          setSearchTerm('');
          setFilter('all');
          setCurrentPage(1);
          fetchOrders();
        }}
        className="inline-flex items-center px-4 py-2 border border-purple-600 text-purple-600 rounded-lg hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors"
      >
        Filtreleri Temizle
      </button>
    </div>
  );

  if (loading && !orders.length) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-600 text-center">
          <p className="text-xl font-semibold mb-2">Bir hata oluştu</p>
          <p>{error}</p>
          <button
            onClick={fetchOrders}
            className="mt-4 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Yeniden Dene
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 sm:p-6 max-w-7xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        {/* Header */}
        <div className="p-4 sm:p-6 border-b border-gray-200">
          <h1 className="text-xl sm:text-2xl font-bold text-purple-600">Siparişler</h1>
          
          <div className="flex flex-col gap-4 mt-4">
            {/* Arama */}
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Sipariş veya müşteri ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              {/* Loading indicator for search */}
              {loading && searchTerm && (
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-600"></div>
                </div>
              )}
            </div>
            
            {/* Filtreler */}
            <div className="flex gap-2 overflow-x-auto pb-2">
              <select
                value={filter}
                onChange={(e) => handleFilterChange(e.target.value)}
                className="px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white whitespace-nowrap"
              >
                <option value="all">Tüm Durumlar</option>
                <option value="sold">Yeni Sipariş</option>
                <option value="placed">İşlemde</option>
                <option value="shipped">Kargoda</option>
                <option value="delivered">Teslim Edildi</option>
                <option value="cancelled">İptal Edilenler</option>
              </select>

              <select
                value={sortConfig.key}
                onChange={(e) => handleSort(e.target.value)}
                className="px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white whitespace-nowrap"
              >
                <option value="created_at">Tarihe Göre</option>
                <option value="total_amount">Tutara Göre</option>
                <option value="order_id">Sipariş ID</option>
                <option value="customer_name">Müşteri Adı</option>
              </select>
            </div>
          </div>
        </div>

        <div className="relative">
          {/* Loading overlay only for the table area */}
          {loading && orders.length > 0 && (
            <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
                <span className="mt-2 text-sm text-gray-600">Yükleniyor...</span>
              </div>
            </div>
          )}

        {/* Conditional rendering for empty state */}
        {orders.length === 0 ? (
          <EmptyState />
        ) : (
          <>
        {/* Desktop Table */}
        <div className="hidden md:block overflow-x-auto">
          <table className="w-full">
            <thead>
            <tr className="bg-gray-50">
                <th className="px-4 py-3 text-left">Sipariş ID</th>
                <th className="px-4 py-3 text-left">Shop Name</th>
                <th className="px-4 py-3 text-left">Müşteri</th>
                <th className="px-4 py-3 text-left">Tarih</th>
                <th className="px-4 py-3 text-left">Durum</th>
                <th className="px-4 py-3 text-left">Tutar</th>
                <th className="px-4 py-3 text-center">Kargo Durumu</th>
                <th className="px-4 py-3 text-center">Ödeme</th>
                <th className="px-4 py-3 text-center">Hediye</th>
                <th className="px-4 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id} className="border-b border-gray-100 hover:bg-gray-50">
                  <td className="px-4 py-3 font-medium">{order.order_id}</td>
                  <td className="px-4 py-3">{order.shop_name}</td>
                  <td className="px-4 py-3">{order.customer_name}</td>
                  <td className="px-4 py-3">{formatDate(order.created_at)}</td>
                  <td className="px-4 py-3">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(order.status)}`}>
                      {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                    </span>
                  </td>
                  <td className="px-4 py-3">{formatPrice(order.total_amount)}</td>
                  <td className="px-4 py-3 text-center">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      order.shipping_status === 'delivered' ? 'bg-green-100 text-green-800' :
                      order.shipping_status === 'in_transit' ? 'bg-blue-100 text-blue-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {order.shipping_status === 'delivered' ? 'Teslim Edildi' :
                       order.shipping_status === 'in_transit' ? 'Yolda' : 'Bekliyor'}
                    </span>
                  </td>
                  <td className="px-4 py-3 text-center">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      order.is_paid ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {order.is_paid ? 'Ödendi' : 'Ödenmedi'}
                    </span>
                  </td>
                  <td className="px-4 py-3 text-center">
                    {order.is_gift ? (
                      <span className="px-2 py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                        Hediye
                      </span>
                    ) : (
                      <span className="text-gray-400">-</span>
                    )}
                  </td>
                  <td>
                    <ViewOrderButton orderId={order.id} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile Cards */}
        <div className="md:hidden">
          {orders.map((order) => (
            <div key={order.id} className="p-4 border-b border-gray-200">
              <div className="flex justify-between items-start mb-3">
                <div>
                  <h3 className="font-medium text-gray-900">#{order.order_id}</h3>
                  <p className="text-sm text-gray-600">{order.shop_name}</p>
                  <span className={`inline-block mt-1 px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(order.status)}`}>
                    {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                  </span>
                </div>
                <ViewOrderButton orderId={order.id} />
              </div>
              
              <div className="space-y-2 text-sm">
                <div className="flex items-center gap-2 text-gray-600">
                  <User size={16} />
                  <span>{order.customer_name}</span>
                </div>
                
                <div className="flex items-center gap-2 text-gray-600">
                  <Calendar size={16} />
                  <span>{formatDate(order.created_at)}</span>
                </div>

                <div className="flex items-center gap-2 text-gray-900 font-medium">
                  <Package size={16} />
                  <span>{formatPrice(order.total_amount)}</span>
                </div>

                <div className="flex flex-wrap gap-2 mt-2">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    order.shipping_status === 'delivered' ? 'bg-green-100 text-green-800' :
                    order.shipping_status === 'in_transit' ? 'bg-blue-100 text-blue-800' :
                    'bg-yellow-100 text-yellow-800'
                  }`}>
                    {order.shipping_status === 'delivered' ? 'Teslim Edildi' :
                     order.shipping_status === 'in_transit' ? 'Yolda' : 'Hazırlanıyor'}
                  </span>
                  
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    order.is_paid ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  }`}>
                    {order.is_paid ? 'Ödendi' : 'Ödenmedi'}
                  </span>

                  {order.is_gift && (
                    <span className="px-2 py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                      Hediye
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>


        {/* Önceki kodun devamı... */}

        {/* Pagination */}
        <div className="p-4 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="text-sm text-gray-600 order-2 sm:order-1">
              Toplam {totalItems} sipariş
            </div>
            <div className="flex gap-2 order-1 sm:order-2">
              <button
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 rounded border border-gray-200 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
              >
                Önceki
              </button>
              <div className="flex items-center gap-1">
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter(page => {
                    const isNearCurrentPage = Math.abs(page - currentPage) <= 1;
                    const isFirstOrLast = page === 1 || page === totalPages;
                    return isNearCurrentPage || isFirstOrLast;
                  })
                  .map((page, index, array) => {
                    if (index > 0 && page - array[index - 1] > 1) {
                      return (
                        <React.Fragment key={`gap-${page}`}>
                          <span className="px-2 text-gray-400">...</span>
                          <button
                            onClick={() => setCurrentPage(page)}
                            className={`w-8 h-8 rounded-full ${
                              currentPage === page
                                ? 'bg-purple-600 text-white'
                                : 'hover:bg-gray-100'
                            }`}
                          >
                            {page}
                          </button>
                        </React.Fragment>
                      );
                    }
                    return (
                      <button
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        className={`w-8 h-8 rounded-full ${
                          currentPage === page
                            ? 'bg-purple-600 text-white'
                            : 'hover:bg-gray-100'
                        }`}
                      >
                        {page}
                      </button>
                    );
                  })}
              </div>
              <button
                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 rounded border border-gray-200 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
              >
                Sonraki
              </button>
            </div>
          </div>
        </div>
        </>
        )}
      </div>
    </div>
    </div>
  );
};

export default OrdersPage;