import React, { useState, useEffect } from 'react';
import { TrendingUp, Package } from 'lucide-react';

const TopProducts = ({ period, show_all_stores, store_id }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productImages, setProductImages] = useState({});

  // Periyoda göre gün sayısını hesaplayan yardımcı fonksiyon
  const getPeriodText = (periodType) => {
    const periodMap = {
      'last7days': '7 Days',
      'last30days': '30 Days',
      'today': '30 Days',
      'thisMonth': 'This Month',
      'lastMonth': 'Month',
      'thisYear': 'This Year',
      'allTime': 'All Time'
    };
    return periodMap[periodType] || '30 Days';
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const queryParams = new URLSearchParams({
          period: period,
          show_all_stores: show_all_stores || false
        });
        
        if (!show_all_stores && store_id) {
          queryParams.append('store_id', store_id);
        }

        const response = await fetch(`https://api.canvashelper.com/api/dashboard/top-products?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to fetch products');
        
        const data = await response.json();
        setProducts(data.products);

        data.products.forEach(product => {
          fetchProductImage(product.imageEndpoint);
        });

      } catch (err) {
        setError(err.message);
        setProducts([]);
      } finally {
        setLoading(false);
      }
    };
    
    const fetchProductImage = async (imageEndpoint) => {
      try {
        const response = await fetch(imageEndpoint, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch image');
        
        const data = await response.json();
        setProductImages(prev => ({
          ...prev,
          [imageEndpoint]: data.url_570xN
        }));
      } catch (err) {
        console.error('Error loading image:', err);
      }
    };

    fetchProducts();
  }, [period, show_all_stores, store_id]);

  if (loading) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="h-8 bg-gray-200 rounded w-1/3 mb-6"></div>
        <div className="space-y-4">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="flex items-start gap-3 animate-pulse">
              <div className="min-w-[40px] h-16 bg-gray-100 rounded-lg"></div>
              <div className="flex-1">
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                <div className="h-3 bg-gray-200 rounded w-1/2 mb-1"></div>
                <div className="h-3 bg-gray-200 rounded w-1/3"></div>
              </div>
              <div className="w-16"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Top Products</h2>
          <span className="text-sm text-gray-500">Last {getPeriodText(period)}</span>
        </div>
        <div className="text-red-500 text-sm">{error}</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Top Products</h2>
        <span className="text-sm text-gray-500">Last {getPeriodText(period)}</span>
      </div>
      {products.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-12 text-center">
          <Package className="w-12 h-12 text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No Products Found</h3>
          <p className="text-sm text-gray-500 max-w-sm">
            There are no sales recorded for this period. Products will appear here once you make your first sale.
          </p>
        </div>
      ) : (
        <div className="space-y-4 max-h-[600px] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          {products.map((product) => (
            <div key={product.id} className="flex items-start gap-3">
              <div className="min-w-[40px] h-16 bg-gray-100 rounded-lg overflow-hidden">
                {productImages[product.imageEndpoint] && (
                  <img 
                    src={productImages[product.imageEndpoint]} 
                    alt={product.title}
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <a 
                  href={`https://www.etsy.com/listing/${product.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm font-medium text-gray-900 hover:text-blue-600 transition-colors line-clamp-2"
                >
                  {product.title}
                </a>
                <p className="text-xs text-gray-500 mt-1">
                  {product.totalQuantity} sales • ${product.totalRevenue.toLocaleString()}
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  {product.shopName}
                </p>
              </div>
              <div className="w-16 text-right shrink-0">
                {product.growth !== 0 && (
                  <div className={`flex items-center justify-end text-xs ${product.growth > 0 ? 'text-green-600' : 'text-red-600'}`}>
                    <TrendingUp className={`w-3 h-3 mr-1 ${product.growth < 0 ? 'rotate-180' : ''}`} />
                    {product.growth}%
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TopProducts;