// Components
import React, { useState, useEffect } from 'react';
import { Check, Plus, Building2, Settings } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

// Constants
const INITIAL_FORM_DATA = {
  name: '',
  contact_info: '',
  lead_time: '',
};

const INITIAL_FORM_ERRORS = {
  name: '',
  contact_info: '',
  lead_time: '',
};

// API Functions
const api = {
  async fetchSuppliers(token) {
    return axios.get('https://api.canvashelper.com/api/suppliers', {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async fetchVariationProfiles(token) {
    return axios.get('https://api.canvashelper.com/api/variation_profiles', {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async fetchVariations(token, profileId) {
    return axios.get(`https://api.canvashelper.com/api/get-variations/${profileId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async fetchSupplierPrices(token, supplierId) {
    return axios.get(`https://api.canvashelper.com/api/get-supplier-variation/${supplierId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async saveSupplier(token, formData) {
    return axios.post(
      'https://api.canvashelper.com/api/suppliers',
      { ...formData, self_suppliers: 1 },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },

  async saveSelectedSupplier(token, supplierId) {
    return axios.post(
      'https://api.canvashelper.com/api/save-selected-supplier',
      { supplier_id: supplierId },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
};

// Component Parts
const TabNavigation = ({ activeTab, setActiveTab }) => (
  <div className="border-b border-gray-200">
    <nav className="flex -mb-px">
      <button
        onClick={() => setActiveTab('suppliers')}
        className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
          activeTab === 'suppliers'
            ? 'border-purple-500 text-purple-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        }`}
      >
        Tedarikçiler
      </button>
      <button
        onClick={() => setActiveTab('variations')}
        className={`py-4 px-1 border-b-2 font-medium text-sm ${
          activeTab === 'variations'
            ? 'border-purple-500 text-purple-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        }`}
      >
        Variation Profilleri
      </button>
    </nav>
  </div>
);

const SupplierCard = ({ supplier, isSelected, isSelfWorkshop, onClick }) => (
  <div
    onClick={onClick}
    className={`cursor-pointer rounded-lg border shadow-sm hover:shadow-md transition-all p-6 bg-white ${
      isSelected ? 'border-purple-500 border-2' : 'border-gray-200'
    }`}
  >
    <div className="flex justify-between items-start">
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          {isSelfWorkshop && <Building2 className="text-purple-500" size={20} />}
          <h3 className="font-semibold text-lg text-purple-500">{supplier.name}</h3>
        </div>
        <div className="text-sm text-gray-600">
          <p className="mb-1">İletişim: {supplier.contact_info}</p>
          <p>Tedarik Süresi: {supplier.lead_time}</p>
        </div>
        {isSelfWorkshop && (
          <span className="inline-block px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded">
            Kendi Atölyem
          </span>
        )}
      </div>
      {isSelected && <Check className="text-purple-500" size={24} />}
    </div>
  </div>
);

const SupplierForm = ({ formData, formErrors, handleFormChange }) => (
  <div className="p-6 border-t border-gray-200 bg-purple-50">
    <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
      {Object.entries(formData).map(([key, value]) => (
        <div key={key}>
          <label className="block text-sm font-medium text-purple-600 mb-1">
            {key === 'name' ? 'Atölye Adı' : 
             key === 'contact_info' ? 'İletişim Bilgisi' : 
             'Tedarik Süresi'}
          </label>
          <input
            type="text"
            name={key}
            value={value}
            onChange={handleFormChange}
            className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 ${
              formErrors[key] ? 'border-red-500' : 'border-purple-200'
            }`}
            placeholder={
              key === 'name' ? 'Atölyenizin adını girin' :
              key === 'contact_info' ? 'İletişim numaranız' :
              'Örn: 5-7 gün'
            }
          />
          {formErrors[key] && <p className="mt-1 text-sm text-red-500">{formErrors[key]}</p>}
        </div>
      ))}
    </form>
  </div>
);

const VariationTable = ({ variations, supplierPrices, isSelfWorkshop, handlePriceChange }) => (
  <table className="w-full">
    <thead className="bg-gray-50 border-b border-gray-200">
      <tr>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Boyut
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Çerçeve
        </th>
        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
          {isSelfWorkshop ? "Fiyat Belirle" : "Tedarikçi Fiyatı"}
        </th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200">
      {variations.map((variation) => (
        <tr key={variation.id}>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {variation.dimension_name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {variation.frame_name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
            {isSelfWorkshop ? (
              <input
                type="number"
                value={supplierPrices[variation.id] || ''}
                onChange={(e) => handlePriceChange(variation.id, e.target.value)}
                className="w-24 p-1 border rounded-md text-right"
                placeholder="0.00"
              />
            ) : (
              <span className="text-gray-900">
                ${supplierPrices[variation.id] || '0.00'}
              </span>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

// Main Component
const SupplierSettings = () => {
  // State Management
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formErrors, setFormErrors] = useState(INITIAL_FORM_ERRORS);
  const [activeTab, setActiveTab] = useState('suppliers');
  const [variationProfiles, setVariationProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [variations, setVariations] = useState([]);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [loadingVariations, setLoadingVariations] = useState(false);
  const [supplierPrices, setSupplierPrices] = useState({});

  const selfWorkshop = suppliers.find((s) => s.is_self_supplier);
  const token = localStorage.getItem('token');

  // Data Fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await api.fetchSuppliers(token);
        if (response.status === 200) {
          setSuppliers(response.data.suppliers);
          setSelectedSupplier(response.data.selected_supplier?.id || null);
        }
      } catch (error) {
        console.error('Tedarikçileri çekerken hata:', error);
        toast.error('Tedarikçiler yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (activeTab === 'variations') {
      const fetchProfiles = async () => {
        setLoadingProfiles(true);
        try {
          const response = await api.fetchVariationProfiles(token);
          if (response.status === 200) {
            setVariationProfiles(response.data);
          }
        } catch (error) {
          console.error('Variation profilleri çekerken hata:', error);
          toast.error('Profiller yüklenirken bir hata oluştu');
        } finally {
          setLoadingProfiles(false);
        }
      };

      fetchProfiles();
    }
  }, [activeTab]);

  useEffect(() => {
    if (selectedProfile) {
      const fetchVariationData = async () => {
        setLoadingVariations(true);
        try {
          const response = await api.fetchVariations(token, selectedProfile);
          if (response.status === 200) {
            const sortedData = sortDimensions(response.data);
            setVariations(response.data);
          }
        } catch (error) {
          console.error('Variations çekerken hata:', error);
          toast.error('Variations yüklenirken bir hata oluştu');
        } finally {
          setLoadingVariations(false);
        }
      };

      fetchVariationData();
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (selectedProfile && selectedSupplier) {
      const fetchPrices = async () => {
        try {
          const response = await api.fetchSupplierPrices(token, selectedSupplier);
          if (response.status === 200) {
            const pricesMap = {};
            response.data.forEach(price => {
              pricesMap[price.variation_id] = price.price;
            });
            setSupplierPrices(pricesMap);
          }
        } catch (error) {
          console.error('Tedarikçi fiyatlarını çekerken hata:', error);
          toast.error('Fiyatlar yüklenirken bir hata oluştu');
        }
      };

      fetchPrices();
    }
  }, [selectedProfile, selectedSupplier]);

  // Event Handlers
  const handleSupplierSelect = (supplierId) => {
    setSelectedSupplier(supplierId);
    setFormErrors(INITIAL_FORM_ERRORS);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setFormErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    if (!showForm) return true;

    const errors = Object.keys(formData).reduce((acc, key) => {
      if (!formData[key].trim()) {
        acc[key] = `${key === 'name' ? 'Atölye adı' : 
                    key === 'contact_info' ? 'İletişim bilgisi' : 
                    'Tedarik süresi'} zorunludur`;
      }
      return acc;
    }, {});

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sortDimensions = (variations) => {
    return variations.sort((a, b) => {
      const [aWidth] = a.dimension_name.split('x').map(num => parseInt(num));
      const [bWidth] = b.dimension_name.split('x').map(num => parseInt(num));
      return aWidth - bWidth;
    });
  };

  const handleSave = async () => {
    if (!validateForm()) {
      toast.error('Lütfen tüm zorunlu alanları doldurun');
      return;
    }

    try {
      if (showForm) {
        await api.saveSupplier(token, formData);
        setShowForm(false);
        setFormData(INITIAL_FORM_DATA);
        toast.success('Atölyeniz başarıyla kaydedildi');
      } else {
        await api.saveSelectedSupplier(token, selectedSupplier);
        toast.success('Tedarikçi seçiminiz kaydedildi');
      }
      
      const response = await api.fetchSuppliers(token);
      if (response.status === 200) {
        setSuppliers(response.data.suppliers);
        setSelectedSupplier(response.data.selected_supplier?.id || null);
      }
    } catch (error) {
      console.error('Kayıt sırasında hata:', error);
      toast.error('İşlem sırasında bir hata oluştu');
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-6 text-purple-600">Tedarikçi Ayarları</h1>

      <div className="mb-6">
        <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 'suppliers' && (
          <div className="mt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {suppliers
                .filter(s => !s.is_self_supplier)
                .map((supplier) => (
                  <SupplierCard
                    key={supplier.id}
                    supplier={supplier}
                    isSelected={selectedSupplier === supplier.id}
                    onClick={() => handleSupplierSelect(supplier.id)}
                  />
                ))}

              {selfWorkshop ? (
                <SupplierCard
                  supplier={selfWorkshop}
                  isSelected={selectedSupplier === selfWorkshop.id}
                  isSelfWorkshop={true}
                  onClick={() => handleSupplierSelect(selfWorkshop.id)}
                />
              ) : (
                <div className="rounded-lg border shadow-sm bg-white">
                  <div
                    onClick={() => setShowForm(true)}
                    className={`cursor-pointer p-6 hover:shadow-md ${
                      showForm ? 'border-purple-500 border-2' : 'border-gray-200'
                    }`}
                  >
                    <div className="flex justify-between items-start">
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <Building2 className="text-purple-500" size={20} />
                          <h3 className="font-semibold text-lg text-purple-600">Kendi Atölyem</h3>
                        </div>
                        <p className="text-sm text-gray-600">Kendi üretimimi yapacağım</p>
                      </div>
                      {showForm ? (
                        <Check className="text-purple-500" size={24} />
                      ) : (
                        <Plus className="text-gray-400" size={24} />
                      )}
                    </div>
                  </div>

                  {showForm && (
                    <SupplierForm
                      formData={formData}
                      formErrors={formErrors}
                      handleFormChange={handleFormChange}
                    />
                  )}
                </div>
              )}
            </div>

            <button
              onClick={handleSave}
              disabled={!selectedSupplier && (!showForm || !formData.name || !formData.contact_info || !formData.lead_time)}
              className={`mt-6 px-4 py-2 rounded-md text-white transition-colors ${
                selectedSupplier || (showForm && formData.name && formData.contact_info && formData.lead_time)
                  ? 'bg-purple-600 hover:bg-purple-700'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
            >
              Kaydet
            </button>
          </div>
        )}

        {activeTab === 'variations' && (
          <div className="mt-6">
            {loadingProfiles ? (
              <div className="flex justify-center items-center h-24">Profiller yükleniyor...</div>
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                  {variationProfiles.map((profile) => (
                    <div
                      key={profile.id}
                      onClick={() => setSelectedProfile(profile.id)}
                      className={`cursor-pointer rounded-lg border shadow-sm hover:shadow-md transition-all p-6 bg-white ${
                        selectedProfile === profile.id ? 'border-purple-500 border-2' : 'border-gray-200'
                      }`}
                    >
                      <div className="flex justify-between items-start">
                        <div className="space-y-2">
                          <h3 className="font-semibold text-lg text-purple-500">{profile.name}</h3>
                          <p className="text-sm text-gray-600">
                            {profile.description || 'Açıklama yok'}
                          </p>
                        </div>
                        {selectedProfile === profile.id && (
                          <Settings className="text-purple-500" size={24} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {selectedProfile && (
                  <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                    {loadingVariations ? (
                      <div className="flex justify-center items-center h-24">
                        Varyasyonlar yükleniyor...
                      </div>
                    ) : (
                      <div className="overflow-x-auto">
                        <VariationTable
                          variations={variations}
                          supplierPrices={supplierPrices}
                          isSelfWorkshop={selectedSupplier === selfWorkshop?.id}
                          handlePriceChange={(variationId, price) => 
                            setSupplierPrices(prev => ({
                              ...prev,
                              [variationId]: price
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default SupplierSettings;