import React from 'react';
import { User } from 'lucide-react';

const CustomerInformation = ({ orderData }) => {
  const { buyer_name, buyer_email, buyer_phone, shipping } = orderData;

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2 mb-6">
          <User className="w-5 h-5 text-gray-500" />
          Customer Information
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Customer Details */}
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-3">Customer Details</h3>
            <div className="space-y-2">
              <p className="text-sm">
                <span className="text-gray-500">Name:</span>{' '}
                <span className="font-medium">{buyer_name || 'N/A'}</span>
              </p>
              <p className="text-sm">
                <span className="text-gray-500">Email:</span>{' '}
                <span className="font-medium">{buyer_email || 'N/A'}</span>
              </p>
              <p className="text-sm">
                <span className="text-gray-500">Phone:</span>{' '}
                <span className="font-medium">{buyer_phone || 'Not provided'}</span>
              </p>
            </div>
          </div>

          {/* Shipping Address */}
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-3">Shipping Address</h3>
            <div className="space-y-2">
              <p className="text-sm font-medium">{shipping?.name || buyer_name}</p>
              <p className="text-sm text-gray-600">
                {shipping?.address1}
                {shipping?.address2 && <><br />{shipping.address2}</>}
              </p>
              <p className="text-sm text-gray-600">
                {shipping?.city}, {shipping?.state} {shipping?.zip}
              </p>
              <p className="text-sm text-gray-600">{shipping?.country}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInformation;