import React, { useState, useEffect } from 'react';
import { Package, ExternalLink } from 'lucide-react';
import { formatPrice, formatVariations } from './helperFunctions';

const OrderProducts = ({ orderData }) => {
  const [productImages, setProductImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});

  useEffect(() => {
    if (orderData?.items) {
      orderData.items.forEach(item => {
        if (item.listing_id && item.listing_image_id) {
          fetchListingImage(item.listing_id, item.listing_image_id);
        }
      });
    }
  }, [orderData]);

  const fetchListingImage = async (listingId, imageId) => {
    if (productImages[listingId]) return;

    try {
      setLoadingImages(prev => ({ ...prev, [listingId]: true }));
      const response = await fetch(
        `https://etsyhelper.onrender.com/api/etsy/listings/${listingId}/images/${imageId}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      
      if (!response.ok) throw new Error('Error fetching image');

      const data = await response.json();
      setProductImages(prev => ({
        ...prev,
        [listingId]: {
          url_75x75: data.url_570xN,
          url_170x135: data.url_570xN,
          url_fullxfull: data.url_fullxfull
        }
      }));
    } catch (err) {
      console.error(`Error fetching image for listing ${listingId}:`, err);
    } finally {
      setLoadingImages(prev => ({ ...prev, [listingId]: false }));
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-4 sm:p-6">
        <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2 mb-6">
          <Package className="w-5 h-5 text-gray-500" />
          Products
        </h2>
        <div className="space-y-6">
          {orderData.items.map((item, index) => (
            <div key={index} className={`${index !== orderData.items.length - 1 ? 'border-b pb-6' : ''}`}>
              <div className="flex flex-col sm:flex-row gap-4">
                {/* Product Image & View Listing Button */}
                <div className="flex sm:flex-col items-start gap-3 sm:w-32">
                  <div className="w-24 h-24 sm:w-32 sm:h-32 bg-gray-100 rounded-lg overflow-hidden flex-shrink-0">
                    {loadingImages[item.listing_id] ? (
                      <div className="w-full h-full flex items-center justify-center">
                        <div className="animate-spin rounded-full h-6 w-6 border-2 border-blue-500 border-t-transparent"></div>
                      </div>
                    ) : productImages[item.listing_id] ? (
                      <img 
                        src={productImages[item.listing_id].url_75x75}
                        alt={item.title}
                        className="w-full h-full object-cover cursor-pointer hover:opacity-75 transition-opacity"
                        onClick={() => window.open(productImages[item.listing_id].url_fullxfull, '_blank')}
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <Package className="w-8 h-8 text-gray-400" />
                      </div>
                    )}
                  </div>
                  <a 
                    href={`https://www.etsy.com/listing/${item.listing_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sm:mt-2 inline-flex items-center justify-center px-3 py-1.5 bg-gray-100 hover:bg-gray-200 text-gray-700 text-sm font-medium rounded-lg transition-colors w-auto sm:w-full"
                  >
                    <ExternalLink className="w-4 h-4 mr-1.5" />
                    View Listing
                  </a>
                </div>

                {/* Product Details */}
                <div className="flex-1 min-w-0">
                  <div className="flex flex-col sm:flex-row justify-between gap-2">
                    <div className="flex-1 min-w-0">
                      <h3 className="text-sm font-medium text-gray-900 break-words">{item.title}</h3>
                      <p className="text-sm text-gray-500 mt-1">SKU: {item.sku || 'Not specified'}</p>
                    </div>
                    <div className="flex sm:flex-col items-baseline sm:items-end gap-2 sm:gap-1 flex-shrink-0">
                      <p className="font-medium text-gray-900">{formatPrice(item.price)}</p>
                      <p className="text-sm text-gray-500">Qty: {item.quantity}</p>
                    </div>
                  </div>

                  {/* Variations */}
                  {item.variations && item.variations.length > 0 && (
                    <div>
                      <p className="text-sm font-medium text-gray-700 mb-2">Options:</p>
                      <div className="flex flex-wrap gap-2">
                        {item.variations.map((variation, idx) => {
                          const formattedVar = formatVariations(variation);
                          return (
                            <div key={idx} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              <span className="text-gray-500 min-w-[60px] sm:min-w-[100px]">{formattedVar.name}:</span>
                              <span className="text-gray-900 font-medium ml-1">{formattedVar.value}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {/* Personalization */}
                  {item.personalization && (
                    <div className="mt-4">
                      <p className="text-sm font-medium text-gray-700 mb-2">Personalization:</p>
                      <p className="text-sm text-gray-600 whitespace-pre-wrap">{item.personalization}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderProducts;