import React, { useState, useEffect } from 'react';
import { Package } from 'lucide-react';

const getStatusColor = (status) => {
  const colors = {
    'Completed': 'bg-green-100 text-green-800',
    'Pre-Transit': 'bg-yellow-100 text-yellow-800',
    'In Transit': 'bg-blue-100 text-blue-800',
    'NotDelivered': 'bg-orange-100 text-orange-800'
  };
  return colors[status] || 'bg-gray-100 text-gray-800';
};

const OrderCard = ({ order }) => (
  <div className="flex items-center gap-4 p-3 bg-gray-50 rounded-lg">
    <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0">
      <Package className="w-5 h-5 text-blue-600" />
    </div>
    <div className="flex-grow">
      <p className="text-sm font-medium text-gray-900">{order.order_number}</p>
      <p className="text-xs text-gray-500">{order.total_items} items • ${order.total_amount}</p>
      <p className="text-xs text-gray-500">{new Date(order.created_at).toLocaleString()}</p>
      {order.shop_name && (
        <p className="text-xs text-gray-500">{order.shop_name}</p>
      )}
    </div>
    <span className={`text-xs font-medium px-2.5 py-0.5 rounded-full ${getStatusColor(order.status)}`}>
      {order.status}
    </span>
  </div>
);

const OrderStatus = ({ period, show_all_stores, store_id }) => {
  const [activeTab, setActiveTab] = useState('pending-feedback');
  const [orders, setOrders] = useState({ 'pending-feedback': [], 'undelivered': [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        // Query parametrelerini oluştur
        const queryParams = new URLSearchParams({
          show_all_stores: show_all_stores || false
        });
        
        // Period varsa ekle
        if (period) {
          queryParams.append('period', period);
        }
        
        // Eğer tüm mağazalar seçili değilse ve store_id varsa ekle
        if (!show_all_stores && store_id) {
          queryParams.append('store_id', store_id);
        }

        const response = await fetch(`https://api.canvashelper.com/api/dashboard/orders/${activeTab}?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
          
        if (!response.ok) throw new Error('Failed to fetch orders');
        const data = await response.json();
        setOrders(prev => ({ ...prev, [activeTab]: data.orders }));
        setError(null);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError(error.message);
        setOrders(prev => ({ ...prev, [activeTab]: [] }));
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [activeTab, period, show_all_stores, store_id]); // Dependencies'e yeni parametreleri ekledim

  const getTabTitle = (tab) => {
    const titles = {
      'pending-feedback': 'Feedback Required',
      'undelivered': 'Undelivered'
    };
    const count = orders[tab]?.length || 0;
    return `${titles[tab]} (${count})`;
  };

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      <div className="flex items-center gap-4 mb-6">
        <button 
          onClick={() => setActiveTab('pending-feedback')}
          className={`text-sm font-medium px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'pending-feedback' ? 'bg-blue-100 text-blue-700' : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          {getTabTitle('pending-feedback')}
        </button>
        <button 
          onClick={() => setActiveTab('undelivered')}
          className={`text-sm font-medium px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'undelivered' ? 'bg-blue-100 text-blue-700' : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          {getTabTitle('undelivered')}
        </button>
      </div>
      
      <div className="max-h-[600px] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        {loading ? (
          <div className="space-y-4">
            {[1, 2, 3].map(i => (
              <div key={i} className="animate-pulse flex gap-4 p-3 bg-gray-50 rounded-lg">
                <div className="w-10 h-10 bg-gray-200 rounded-full" />
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-1/4" />
                  <div className="h-3 bg-gray-200 rounded w-1/2" />
                </div>
              </div>
            ))}
          </div>
        ) : error ? (
          <div className="text-center py-8 text-red-500">
            {error}
          </div>
        ) : orders[activeTab].length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No {activeTab === 'pending-feedback' ? 'feedback required' : 'undelivered'} orders found
            {!show_all_stores && store_id ? ' for the selected store' : ''}
          </div>
        ) : (
          <div className="space-y-4">
            {orders[activeTab].map((order) => (
              <OrderCard key={order.id} order={order} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderStatus;