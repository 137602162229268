import React, { useState, useEffect } from 'react';
import { Star, ExternalLink, ChevronDown, ChevronUp } from 'lucide-react';

const truncateText = (text, limit) => {
  if (text.length <= limit) return text;
  return `${text.slice(0, limit)}...`;
};

const StarRating = ({ rating }) => (
  <div className="flex">
    {[...Array(5)].map((_, index) => (
      <Star 
        key={index}
        className={`w-3 h-3 ${
          index < rating 
            ? 'text-yellow-400 fill-current' 
            : 'text-gray-300 fill-current'
        }`}
      />
    ))}
  </div>
);

const ReviewCard = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const etsyProductUrl = `https://www.etsy.com/listing/${review.listingId}`;
  const isLongComment = review.comment.length > 60;

  return (
    <div className="p-3 bg-gray-50 rounded-lg">
      <div className="flex items-start justify-between mb-2">
        <div className="flex-grow">
          <StarRating rating={review.rating} />
          <div className="flex items-center gap-2 mt-1">
            <p className="text-sm font-medium text-gray-900">{review.customerName}</p>
          </div>
          <a 
            href={etsyProductUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-xs text-gray-500 hover:text-blue-600 mt-1 group"
          >
            {truncateText(review.product.name, 40)}
            <ExternalLink className="w-3 h-3 ml-1 opacity-0 group-hover:opacity-100 transition-opacity" />
          </a>
        </div>
        {review.product.imageUrl && (
          <img 
            src={review.product.imageUrl} 
            alt="Product" 
            className="w-12 h-12 rounded-lg object-cover ml-2"
          />
        )}
      </div>
      
      <div className="text-sm text-gray-600">
        {isExpanded ? review.comment : truncateText(review.comment, 60)}
        {isLongComment && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-2 text-blue-600 hover:text-blue-700 text-xs font-medium inline-flex items-center"
          >
            {isExpanded ? (
              <>Show less <ChevronUp className="w-3 h-3 ml-0.5" /></>
            ) : (
              <>Read more <ChevronDown className="w-3 h-3 ml-0.5" /></>
            )}
          </button>
        )}
      </div>
      
      <div className="flex items-center justify-between mt-2">
        <p className="text-xs text-gray-500">{review.date.display}</p>
        {review.needsResponse && (
          <span className="text-xs px-2 py-0.5 rounded-full bg-orange-100 text-orange-800">
            Needs Response
          </span>
        )}
      </div>
    </div>
  );
};

const RecentReviews = ({ period, show_all_stores, store_id }) => {
  const [reviewData, setReviewData] = useState({ reviews: [], stats: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const queryParams = new URLSearchParams({
          show_all_stores: show_all_stores || false
        });
        
        if (period) {
          queryParams.append('period', period);
        }
        
        if (!show_all_stores && store_id) {
          queryParams.append('store_id', store_id);
        }

        const response = await fetch(`https://api.canvashelper.com/api/dashboard/recent-reviews?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch reviews');
        }
        
        const data = await response.json();
        data.reviews = data.reviews.slice(0, 5);
        setReviewData(data);
      } catch (err) {
        setError(err.message);
        setReviewData({ reviews: [], stats: null });
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [period, show_all_stores, store_id]);

  if (loading) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="h-8 bg-gray-200 rounded w-1/3 mb-6"></div>
        <div className="grid grid-cols-3 gap-4 mb-6">
          {[1, 2, 3].map(i => (
            <div key={i} className="h-16 bg-gray-100 rounded animate-pulse"></div>
          ))}
        </div>
        <div className="space-y-4">
          {[1, 2, 3].map(i => (
            <div key={i} className="p-3 bg-gray-50 rounded-lg animate-pulse">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-3 bg-gray-200 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="text-center py-6">
          <p className="text-red-500 mb-2">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="text-sm text-blue-600 hover:text-blue-700"
          >
            Try again
          </button>
        </div>
      </div>
    );
  }

  const { reviews, stats } = reviewData;

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Recent Reviews</h2>
        {stats && !show_all_stores && (
          <div className="flex items-center gap-1">
            <span className="font-medium text-gray-900">Avg {stats.averageRating}</span>
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
          </div>
        )}
      </div>

      {reviews.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          No reviews yet
        </div>
      ) : (
        <div className="max-h-[600px] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          <div className="space-y-4">
            {reviews.map((review) => (
              <ReviewCard key={review.id} review={review} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentReviews;