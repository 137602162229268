import React from 'react';
import { CheckCircle, DollarSign, Truck } from 'lucide-react';
import { formatDate } from './helperFunctions';

const OrderTimeline = ({ orderData }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Order Timeline</h2>
        <div className="space-y-6">
          {orderData.dates.created && (
            <div className="flex items-start gap-4">
              <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                <CheckCircle className="w-4 h-4 text-blue-600" />
              </div>
              <div>
                <p className="font-medium text-gray-900">Order Created</p>
                <p className="text-sm text-gray-500">{formatDate(orderData.dates.created)}</p>
              </div>
            </div>
          )}
          {orderData.items[0]?.dates?.paid && (
            <div className="flex items-start gap-4">
              <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0">
                <DollarSign className="w-4 h-4 text-green-600" />
              </div>
              <div>
                <p className="font-medium text-gray-900">Payment Received</p>
                <p className="text-sm text-gray-500">{formatDate(orderData.items[0].dates.paid)}</p>
              </div>
            </div>
          )}
          {orderData.items[0]?.dates?.shipped && (
            <div className="flex items-start gap-4">
              <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                <Truck className="w-4 h-4 text-purple-600" />
              </div>
              <div>
                <p className="font-medium text-gray-900">Order Shipped</p>
                <p className="text-sm text-gray-500">{formatDate(orderData.items[0].dates.shipped)}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderTimeline;