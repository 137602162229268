// Format functions that will be used across components
export const formatPrice = (priceObj) => {
    if (!priceObj || !priceObj.amount) return '$0.00';
    const price = priceObj.amount / priceObj.divisor;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: priceObj.currency || 'USD',
    }).format(price);
  };
  
  export const formatDate = (dateStr) => {
    if (!dateStr) return 'Not Specified';
    return new Intl.DateTimeFormat(undefined, { 
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).format(new Date(dateStr));
  };
  
  export const decodeHtmlEntities = (text) => {
    if (!text) return '';
    const element = document.createElement('div');
    element.innerHTML = text;
    return element.textContent;
  };
  
  export const formatVariations = (variation) => {
    return {
      name: decodeHtmlEntities(variation.formatted_name),
      value: decodeHtmlEntities(variation.formatted_value)
    };
  };
  
  export const calculateTotalProfit = (orderData) => {
    if (!orderData || !orderData.items) return null;

    const productRevenue = orderData.items.reduce((sum, item) => {
        const price = item.price.amount / item.price.divisor;
        return sum + (price * item.quantity);
      }, 0);
    
        // Calculate production cost (from items)
        const productionCost = orderData.items.reduce((sum, item) => {
          const cost = item.production_cost?.amount ? (item.production_cost.amount / item.production_cost.divisor) : 0;
          return sum + (cost * item.quantity);
        }, 0);
    
        // Calculate shipping costs
        const shippingRevenue = orderData.shipping?.cost ? 
          (orderData.shipping.cost.amount / orderData.shipping.cost.divisor) : 0;
        
          const shippingCost = orderData.packages?.reduce((total, pkg) => {
            return total + (pkg.shipping_cost ? parseFloat(pkg.shipping_cost) : 0);
          }, 0) || 0;
        
        // Calculate discount and sales tax
        const discountAmount = orderData.discount ? 
          (orderData.discount.amount / orderData.discount.divisor) : 0;
        const salesTax = orderData.sales_tax ? 
          (orderData.sales_tax.amount / orderData.sales_tax.divisor) : 0;
    
        // Calculate total revenue before discounts
        const productRevenueWithTax = productRevenue + salesTax;
        const totalRevenue = productRevenue + shippingRevenue + salesTax;
    
        // Calculate revenues after discount
        const itemsRevenueAfterDiscount = productRevenue - discountAmount; // For transaction fee
        const totalRevenueAfterDiscount = totalRevenue - discountAmount;   // For processing fee
    
        // Calculate Etsy fees
        const transactionFee = itemsRevenueAfterDiscount * 0.065; // 6.5% of items only, after discount
        const shippingTransactionFee = shippingRevenue * 0.065; // 6.5% of shipping fee
        
        // Payment processing fee: 6.5% of (product price + shipping) after discount, plus $0.25
        const paymentFee = (totalRevenueAfterDiscount * 0.065) + 0.25; // 6.5% + $0.25
        
        // Calculate listing fee based on quantity for each unique item
        const listingFee = 0.20 * orderData.items.length; // $0.20 per listing
    
        // Calculate total fees and profit
        const totalCosts = productionCost + shippingCost;
        const totalFees = transactionFee + shippingTransactionFee + paymentFee + listingFee + discountAmount + salesTax;
        const netProfit = totalRevenue - totalFees - totalCosts;
        
        return {
          revenue: {
            products: productRevenueWithTax,
            shipping: shippingRevenue,
            total: totalRevenue,
            itemsAfterDiscount: itemsRevenueAfterDiscount,
            totalAfterDiscount: totalRevenueAfterDiscount
          },
          costs: {
            production: productionCost,
            shipping: shippingCost,
            total: totalCosts
          },
          fees: {
            transaction: transactionFee,
            shippingTransaction: shippingTransactionFee,
            payment: paymentFee,
            listing: listingFee,
            discount: discountAmount,
            salesTax: salesTax,
          },
          totalFees,
          netProfit,
          margin: ((netProfit / totalRevenue) * 100).toFixed(2)
        };
    };
  
  export const getStatusBadge = (status) => {
    const statusColors = {
      'sold': 'bg-green-100 text-green-800',
      'pending': 'bg-yellow-100 text-yellow-800',
      'cancelled': 'bg-red-100 text-red-800'
    };
    
    return `px-3 py-1 rounded-full text-sm font-medium ${statusColors[status] || 'bg-gray-100 text-gray-800'}`;
  };