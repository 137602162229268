import React from 'react';
import { DollarSign } from 'lucide-react';
import { formatPrice, calculateTotalProfit } from './helperFunctions';

const ProfitAnalysis = ({ orderData }) => {
  const profitData = calculateTotalProfit(orderData);

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2 mb-4">
          <DollarSign className="w-5 h-5 text-gray-500" />
          Profit Analysis
        </h2>
        <div className="space-y-6">
          {/* Revenue Section */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-base font-semibold text-gray-900 mb-3">Revenue</h3>
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Product Sales</span>
                <span className="text-gray-900 font-medium">
                  {formatPrice({ amount: profitData.revenue.products * 100, divisor: 100, currency: 'USD' })}
                </span>
              </div>
              {profitData.revenue.shipping > 0 && (
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Shipping Fee</span>
                  <span className="text-gray-900 font-medium">
                    {formatPrice({ amount: profitData.revenue.shipping * 100, divisor: 100, currency: 'USD' })}
                  </span>
                </div>
              )}
              {profitData.fees.discount > 0 && (
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Discount</span>
                  <span className="text-red-600 font-medium">
                    -{formatPrice({ amount: profitData.fees.discount * 100, divisor: 100, currency: 'USD' })}
                  </span>
                </div>
              )}
              <div className="flex justify-between pt-2 mt-2 border-t border-gray-200">
                <span className="font-medium">Total Revenue</span>
                <span className="text-green-600 font-medium">
                  {formatPrice({ amount: (profitData.revenue.total - profitData.fees.discount) * 100, divisor: 100, currency: 'USD' })}
                </span>
              </div>
            </div>
          </div>

          {/* Costs Section */}
          {(profitData.costs.production > 0 || profitData.costs.shipping > 0) && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-base font-semibold text-gray-900 mb-3">Direct Costs</h3>
              <div className="space-y-2">
                {profitData.costs.production > 0 && (
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Production Cost</span>
                    <span className="text-red-600 font-medium">
                      -{formatPrice({ amount: profitData.costs.production * 100, divisor: 100, currency: 'USD' })}
                    </span>
                  </div>
                )}
                {profitData.costs.shipping > 0 && (
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Shipping Cost</span>
                    <span className="text-red-600 font-medium">
                      -{formatPrice({ amount: profitData.costs.shipping * 100, divisor: 100, currency: 'USD' })}
                    </span>
                  </div>
                )}
                <div className="flex justify-between pt-2 mt-2 border-t border-gray-200">
                  <span className="font-medium">Total Costs</span>
                  <span className="text-red-600 font-medium">
                    -{formatPrice({ amount: profitData.costs.total * 100, divisor: 100, currency: 'USD' })}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Fees Section */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-base font-semibold text-gray-900 mb-3">Fees & Charges</h3>
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <div className="flex items-center gap-1">
                  <span className="text-gray-600">Etsy Transaction Fee</span>
                  <span className="text-xs text-gray-500">(6.5%)</span>
                </div>
                <span className="text-red-600 font-medium">
                  -{formatPrice({ amount: profitData.fees.transaction * 100, divisor: 100, currency: 'USD' })}
                </span>
              </div>
              {profitData.fees.shippingTransaction > 0 && (
                <div className="flex justify-between text-sm">
                  <div className="flex items-center gap-1">
                    <span className="text-gray-600">Shipping Transaction Fee</span>
                    <span className="text-xs text-gray-500">(6.5%)</span>
                  </div>
                  <span className="text-red-600 font-medium">
                    -{formatPrice({ amount: profitData.fees.shippingTransaction * 100, divisor: 100, currency: 'USD' })}
                  </span>
                </div>
              )}
              <div className="flex justify-between text-sm">
                <div className="flex items-center gap-1">
                  <span className="text-gray-600">Payment Processing</span>
                  <span className="text-xs text-gray-500">(6.5% + $0.25)</span>
                </div>
                <span className="text-red-600 font-medium">
                  -{formatPrice({ amount: profitData.fees.payment * 100, divisor: 100, currency: 'USD' })}
                </span>
              </div>
              <div className="flex justify-between text-sm">
                <div className="flex items-center gap-1">
                  <span className="text-gray-600">Listing Fee</span>
                  <span className="text-xs text-gray-500">(per item)</span>
                </div>
                <span className="text-red-600 font-medium">
                  -{formatPrice({ amount: profitData.fees.listing * 100, divisor: 100, currency: 'USD' })}
                </span>
              </div>
              {profitData.fees.salesTax > 0 && (
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Sales Tax</span>
                  <span className="text-red-600 font-medium">
                    -{formatPrice({ amount: profitData.fees.salesTax * 100, divisor: 100, currency: 'USD' })}
                  </span>
                </div>
              )}
              <div className="flex justify-between pt-2 mt-2 border-t border-gray-200">
                <span className="font-medium">Total Fees</span>
                <span className="text-red-600 font-medium">
                  -{formatPrice({ amount: (profitData.totalFees - profitData.fees.discount) * 100, divisor: 100, currency: 'USD' })}
                </span>
              </div>
            </div>
          </div>

          {/* Final Profit */}
          <div className="bg-gray-50 p-4 rounded-lg mt-4">
            <div className="flex justify-between items-center text-lg">
              <span className="font-semibold">Net Profit</span>
              <span className={`font-semibold ${profitData.netProfit >= 0 ? "text-green-600" : "text-red-600"}`}>
                {formatPrice({ amount: profitData.netProfit * 100, divisor: 100, currency: 'USD' })}
              </span>
            </div>
            <div className="flex justify-between items-center mt-2 text-sm text-gray-600">
              <span>Profit Margin</span>
              <span>{profitData.margin}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitAnalysis;