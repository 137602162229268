import React, { useState } from 'react';

const OrderNotes = () => {
  const [note, setNote] = useState('');

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Notes</h2>
        <div className="space-y-4">
          <textarea 
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="w-full min-h-32 p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none"
            placeholder="Add a note about this order..."
          />
          <button className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
            Save Note
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderNotes;